<template>
  <div class="main-container">
    <header v-once>
        <hr />
        <nav class="nav-menu">
          <h1 class="title" @click="goHome()">Nikoletta Monyok</h1>
          <ul>
            <li v-for="item, index in menuItems" :key="index">
              <a @click="onItemClick(item)">{{ item.title }}</a>
            </li>
          </ul>
        </nav>
        <hr />
    </header>
    <div :class="{ content_without_menu: isCollapsed, content_with_menu: !isCollapsed }">
        <div class="content-container">
          <div v-for="(item, index) in filteredItems" :key="item.id" class="item">
            <div v-if="index === current_index" :key="item.id" class="post" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
              <h2 class="post-title">{{ item.title }}</h2>
              <img :alt="item.title" :src="get_image_url(item)" :style="{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s ease-in-out' }" @click="showGallery(item, index)" class="item_img" :class="{pointer: filterField == 'film' || index > 0}" @load="imageLoadedCb"/>
              <div class="btn_container">
                  <button class="prev_next prev" @click="gallery_back" :disabled="toggler">
                    <span class="material-symbols-outlined shadow">arrow_back</span>
                  </button>
                  <button class="prev_next next" @click="gallery_forward" :disabled="toggler">
                    <span class="material-symbols-outlined shadow">arrow_forward</span>
                  </button>
              </div>
              <div class="item-container" v-if="imageLoaded">
                <hr style="width: 50%;margin: 10px auto;"/>
                <div v-for="(paragraph, index) in item.content" :key="index" class="paragraph">
                  <p>{{ paragraph.children[0].text }}</p>
                </div>
                <br />
                <hr style="width: 50%;margin: 0 auto;"/>
                <br />
              </div>
            </div>
          </div>
        </div>
    </div>
    <FsLightbox
        v-if="gallery.length > 0"
        :toggler="toggler"
        :sources="filteredImages"
        :slide="slide"
        :exitFullscreenOnClose="true"
        :slideDistance="0.5"
        @close="handleClose"
        />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  components: { },
  data() {
    return {
      baseUrl: "https://geniusmuppet.com/api/api",
      items: [],
      paragraphs: [],
      isLoading: false,
      imageLoaded: false,
      current_index: 1,
      gallery: [],
      slide: 1,
      touchStartX: 0,
      touchStartY: 0,
      menuItems: [
        {
          title: 'Photography',
          icon: {
            element: 'i',
            class: "fa fa-image",
          }
        },
        {
          title: 'Films',
          icon: {
            element: 'i',
            class: "fa fa-film",
          }
        },
        {
          title: 'Info',
          icon: {
            element: 'i',
            class: "fa fa-film",
          }
        }
      ],
      isCollapsed: true,
      filterField: "gallery",
      toggler: false
    };
  },
  props: {
    msg: String
  },
  computed: {
    filteredItems() {
      return this.items
          .filter(item => item.type === this.filterField)
          .sort((a,b) => a.order - b.order);
    },
    filteredImages() {
      if (this.filterField === 'gallery') {
        return this.gallery
          .flatMap(item => item.gallery ? item.gallery.map(galleryItem => galleryItem.formats.large.url) : [])
          .sort((a, b) => {
            // Return unsorted if either URL doesn't contain a number
            if (!a || !b) return 0;
            
            const numA = this.extractNumberFromUrl(a) || 0;
            const numB = this.extractNumberFromUrl(b) || 0;
            return numA - numB;
          });  
      } else if (this.filterField === 'film' && this.gallery[0].title === 'Mujo') {
        console.log("Mujo")
        return this.gallery.flatMap(item => item.gallery ? item.gallery.map(galleryItem => galleryItem.url) : [])
      } else {
        console.log("yt")
        return this.gallery
          .map(item => item.Film_link)
          .filter(link => link !== undefined && link !== null);
      }
    },
    isMobile() {
      return /Mobi|Android|iPhone/i.test(window.navigator.userAgent);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleTouchStart(event) {
      const touch = event.touches[0];

      this.touchStartX = touch.clientX;
      this.touchStartY = touch.clientY;
    },

    handleTouchEnd(event) {
      const touch = event.changedTouches[0];
      const endX = touch.clientX;
      const endY = touch.clientY;

      const diffX = endX - this.touchStartX;
      const diffY = endY - this.touchStartY;

      if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX > 0) {
          // swipe right
          if (!this.toggler) {
            this.gallery_back();
          }
        } else {
          // swipe left
          if (!this.toggle) {
            this.gallery_forward();
          }
        }
      }
    },

    async fetchData() {
      this.isLoading = true;
      try {
        const token = '7a0a42432f540304a89b7d115f55d4aa9738deeb955952f96fbaefd40b4c5140c26f55254ff91fea42dd9f4a31a8639c7b8abab52c8ab8b4722e35d1c8ff5f3c02e7084bd766c14c7ceafd31091436f17f91fc0fc911207aebcae8333c17514d546c4b87aefe296504129f15ab36328f16320adb66256a634bad940c0dc55451';
        const response = await axios.get('https://geniusmuppet.com/api/api/posts?populate[0]=cover&populate[1]=gallery&pagination[page]=1&pagination[pageSize]=40&sort=order', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.items = Array.isArray(response.data.data) ? response.data.data : [];
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'instant' })
    },

    onToggleCollapse(collapsed) {
      this.isCollapsed = collapsed;
    },

    gallery_forward() {
      this.imageLoaded = false
      if (this.current_index + 1 >= this.filteredItems.length) {
        this.current_index = this.filterField == 'gallery' ? 1 : 0
      } else {
        this.current_index = this.current_index + 1
      }
    },

    gallery_back() {
      this.imageLoaded = false
      if (this.filterField == 'gallery' && this.current_index - 1 < 1) {
        this.current_index = this.filteredItems.length -1
      } else if (this.current_index -1 < 0) {
        this.current_index = this.filteredItems.length -1
      } else {
        this.current_index = this.current_index - 1
      }
    },

    goHome() {
      this.current_index = 0
      this.filterField = "gallery"
    },

    onItemClick(item) {
      this.imageLoaded = false;
      this.scrollToTop();
      this.current_index = 0
      if (item.title === "Photography") {
        this.current_index = 1
        this.filterField = "gallery";
      } else if (item.title == "Info") {
        this.filterField = "gallery";
      } else {
        this.filterField = "film"
      }
    },

    readMoreToggle() {
      this.readMore = !this.readMore;
    },

    async showGallery(item, index) {
      try {
        const lightboxModule = await import('fslightbox-vue/v3');
        const FsLightbox = lightboxModule.default;

        this.$options.components.FsLightbox = FsLightbox;

        if (this.filterField == 'film' || this.filterField === 'gallery' && index > 0) {
          this.gallery = [item];
          this.$nextTick(() => {
            this.toggler = !this.toggler;
          });
        } else {
          console.log(this.filterField)
          console.log("missed this one")
        }
      } catch (error) {
        console.error('Error loading lightbox:', error);
      }
    },

    handleClose() {
      this.toggler = !this.toggler;
      this.gallery = [];
    },

    imageLoadedCb() {
      this.imageLoaded = true;
    },

    extractNumberFromUrl(url) {
      try {
        if (!url) return 0;
        const match = url.match(/_(\d+)_/);
        return match ? parseInt(match[1], 10) : 0;
      } catch (error) {
        console.error('Error extracting number from URL:', error);
        return 0;
      }
    },

    get_image_url(item) {
      if (item.type === 'gallery') {
        return item.cover.url;
      } else {
        if (item.Film_link) {
          const regex = new RegExp('(?:https?:\\/\\/)?(?:www\\.)?(?:youtube\\.com\\/(?:[^\\/\\n\\s]+\\/\\S+\\/|(?:v|e(?:mbed)?)\\/|.*[?&]v=)|youtu\\.be\\/)([a-zA-Z0-9_-]{11})');
          const match = item.Film_link.match(regex);
    
          if (match && match[1]) {
              const videoId = match[1];
              return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
          }
        }
        return item.cover.url;
      }
    }

  }
};
</script>
